body {
  margin: 0;
  font-family: 'Inter','Poppins', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.bright-4000 {filter:brightness(4000%)}
.logoappcenter {margin: 0 auto}
/* Estilos para a navegação lateral */
.mobile-nav {
  position: fixed;
  top: 0;
  left: -100%; /* Esconde inicialmente */
  width: 80%; /* Largura do menu lateral */
  height: 100%;
  background: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease; /* Animação */
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000;
}

.mobile-nav.visible {
  left: 0; /* Mostra o menu */
}

@media (min-width: 1024px) {
  .mobile-nav {
    display: none; /* Esconde o menu lateral em telas maiores */
  }
}

/* Estilos adicionais para os links e botões no menu lateral */
.mobile-nav a, .mobile-nav button {
  display: block;
  margin-bottom: 10px;
  /* Estilos adicionais conforme necessário */
}

/* Estilos para os links e botões no menu lateral */
.mobile-nav a, .mobile-nav button {
  display: block;
  margin-bottom: 10px;
  color: #4A5568; /* Cor do texto */
  text-decoration: none; /* Remove o sublinhado dos links */
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px
}

.mobile-nav button {
  background: #E2E8F0; /* Cor de fundo do botão */
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

.mobile-nav a:last-child {
  color: #fff;
  padding: 4px 10px;
  text-align: center
}

.mobile-nav img {
  margin-bottom: 30px;
}


/* Ajuste para esconder o botão "Começar" na versão para telas maiores */
.cta-button {
  display: none;
}

@media (min-width: 1024px) {
  .cta-button {
    display: block;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.header-nav {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center;
}

.header-nav li {
  margin: 0 10px; /* Espaço horizontal entre os itens */
}

@media (max-width: 1024px) {
  .mobilebtn100 {
    width: 100%;
  }
}
/*
main.formhome {
  max-width: 960px
}
*/
.logoform {
  margin: 0 auto;
  margin-bottom: 20px;
}

.formhome h2 {
  margin-bottom: 20px
}
.pickup-label, .delivery-label {
  display: block;
  text-align: left;
  margin-bottom: 0.5rem; /* Espaço entre o label e o input */
}

.pickup-input, .delivery-input {
  display: block;
  width: 100%;
  /* Adicione qualquer outro estilo necessário para os inputs aqui */
}

.precos-table {
  width: 100%;
  border-collapse: collapse;
}

.precos-table th, .precos-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.precos-table th {
  background-color: #f4f4f4;
}

/* Estilos adicionais conforme necessário */

header {
  margin-bottom: 10px
}

footer {
  margin-top: 40px
}

.pagetitleh1 {
  font-weight: bolder;
  margin-bottom: 20px;
}

.hometitleh2 {
  font-weight: bolder;
  font-size: 140%;
}

.hamburger-button {
  width: 50px;
  height: 50px;
}

.hamburger-button svg {
  margin-left: 13px
}

/* Estilização do Formulário de Cadastro de Entregador */
.register-form {
  background-color: #fff; /* Cor de fundo */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
}

.register-form h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.register-form input,
.register-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Garante que padding não adicione largura ao elemento */
}

.register-form button {
  width: 100%;
  background-color: #4A5568; /* Cor do botão */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-form button:hover {
  background-color: #2D3748; /* Cor do botão ao passar o mouse */
}

footer ul>li:first-child {font-size: 120%; margin-bottom: 10px}

/* mercado pago */

#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

#form-checkout > .container {
  height: 18px;
  display: inline-block;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
  padding: 1px 2px;
}

a.cta-button, button {
  transition: all 0.2s ease;
}

.menuwide a {
  font-weight: bold;
  color: #ded1f9;
  transition: all 0.2s ease;
}

.menuwide a:hover {
  color: #fff;
}
.pickup-label, .delivery-label, .block.text-left {
  display: block;
  text-align: left;
  margin-bottom: 0.5rem; /* Espaço entre o label e o input */
}

@media (max-width: 1024px) {
  .form-container,
  .map-container {
    width: 100%;
    height:300px;
  }
}

@media (min-width: 1025px) {
  .form-container,
  .map-container {
    width: 50%;
  }
}

.steps-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.step {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ded1f9;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step-number {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  background-color: #ded1f9;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.step-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4a5568;
  text-align: left;
}

.step-content p {
  margin: 0;
  color: #4a5568;
  text-align: left;
}



.pagetitleh1 {
  font-weight: bolder;
  margin-bottom: 20px;
  text-align: center;
}

.precios-table-container {
  padding: 1rem;
  border: 2px solid #ded1f9;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.precios-table {
  width: 100%;
  border-collapse: collapse;
}

.precios-table th,
.precios-table td {
  border: 1px solid #ded1f9;
  padding: 0.5rem;
  text-align: left;
}

.precios-table th {
  background-color: #f9f9ff;
  color: #333;
  font-weight: bold;
}

.precios-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.precios-table tr:hover {
  background-color: #ded1f9;
  color: #fff;
}

.precios-table td {
  color: #555;
}
